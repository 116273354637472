 /*TODO: WHITE LABELING FONT*/

 @font-face {
  font-family: 'Fort Bold';
  font-style: bold;
  src: url(./font/Poppins-SemiBold.ttf); /* TODO FONT CHANGES */
}

@font-face {
  font-family: 'Fort Light';
  font-style: normal;
  src: url(./font/Poppins-Regular.ttf);  /* TODO FONT CHANGES */
}

.nutritionHght {
  height: 30%;
}

.borderLine {
  height: 60%;
  width: 60%;
  border: 1px solid black;
  border-collapse: collapse;
}

/* @font-face {
  font-family: 'Century Gothic Bold';
  font-style: bold;
  font-weight: 400;
  src: local('Century Gothic Bold'), url(./font/CenturyGothic-Bold/CenturyGothic-Bold.woff) format('woff');
} */

*/ body {
  position: relative;
}

html,
body {
  height: 100%;
}

my-app {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto;
  min-height: 80px;
  box-sizing: border-box;
  padding: 30px;
}

@keyframes k-loading-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.k-i-loading.k-example-loading {
  font-size: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(144, 152, 165);
}

.k-i-loading.k-example-loading::before,
.k-i-loading.k-example-loading::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  content: "";
  box-sizing: inherit;
  border-radius: 50%;
  border-width: .05em;
  border-style: solid;
  border-color: currentColor;
  border-top-color: transparent;
  border-bottom-color: transparent;
  background-color: transparent;
}

.k-icon.k-i-loading.k-example-loading::before,
.k-icon.k-i-loading::after {
  content: "";
}

.k-i-loading.k-example-loading::before {
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
  height: 1em;
  animation: k-loading-animation .7s linear infinite;
}

.k-i-loading.k-example-loading::after {
  margin-top: -0.25em;
  margin-left: -0.25em;
  width: .5em;
  height: .5em;
  animation: k-loading-animation reverse 1.4s linear infinite;
}

.example-wrapper {
  min-height: 280px;
  align-content: flex-start;
}

.example-wrapper p,
.example-col p {
  margin: 20px 0 10px;
}

.example-wrapper p:first-child,
.example-col p:first-child {
  margin-top: 0;
}

.example-col {
  display: inline-block;
  vertical-align: top;
  padding-right: 20px;
  padding-bottom: 20px;
}

.example-config {
  margin: 0 0 20px;
  padding: 20px;
  background-color: rgba(0, 0, 0, .03);
  border: 1px solid rgba(0, 0, 0, .08);
}

.event-log {
  margin: 0;
  padding: 0;
  max-height: 100px;
  overflow-y: auto;
  list-style-type: none;
  border: 1px solid rgba(0, 0, 0, .08);
  background-color: white;
}

.event-log li {
  margin: 0;
  padding: .3em;
  line-height: 1.2em;
  border-bottom: 1px solid rgba(0, 0, 0, .08);
}

.event-log li:last-child {
  margin-bottom: -1px;
}

.cursor-pointer {
  cursor: pointer;
}

.showMore {
  text-align: end;
}

.forgot-password {
  margin-top: 20px;
  text-decoration: underline;
  text-align: center;
  color: gray;
}

.text-left {
  text-align: center;
}

.link {
  color: gray;
  font-weight: 300;
}

.number {
  width: 100%;
  position: relative;
  left: -25px;
}

.justify-content-center {
  justify-content: center !important;
}

.minus,
.plus {
  width: 25px;
  height: 25px;
  background: #264350;
  padding: 0;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  position: relative;
  top: 0;
  color: #fff;
}

.number input {
  width: 70px;
  height: 24px;
  border: 1px solid #ccc;
  text-align: center;
}

.text-center {
  text-align: center;
}

.card-text-font {
  font-size: 20px;
}

.card-text-font2 {
  font-size: 50px;
}

.card-text-font4 {
  font-size: 20px;
}

.m-t-15 {
  margin-top: 15px !important;
}

.header-links li span {
  color: white;
}

.modal {
  display: block;
}

.login .input {
  width: 70%;
}

.logo {
  display: flex;
  flex-direction: column;
}

.login-button {
  width: 70%;
  margin-top: 15px;
}

.modal-header {
  padding: 10px;
  border-bottom: none;
}



#breadcrumb .breadcrumb-header {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 15px;
  text-transform: uppercase;
}

.modal-body {
  padding: 0px 0px 30px -15px;
}

.close {
  font-size: 31px;
}


.fp input {
  width: 50%;
}

.otpInput {
  margin-right: 12px;
  width: 50px;
  padding: 11px;
}

.otpInput:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ff8880;
  outline: 0;
  box-shadow: none;
}

.mt-3 {
  margin-top: 13px;
}

.errorMsg {
  color: red;
}

.store-info-card {
  display: flex;
  justify-content: center;
  flex-direction: row;
  /* width: 100%; */
  background: #ffffff;
  margin-right: 30px;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 0px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
}

.p1 {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 100;
}

.p2 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.p3 {
  font: 1em sans-serif;
  font-weight: 700;
}

.ti-bell:before {
  content: "\e6b8";
}

.card-dashboard {
  /* height: 200px; */
  /* width: 50%; */
  background: #ffffff;
  /* margin: 7.5px 10px; */
  /* padding: 50px; */
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
}

.card-analytics-dashboard {
  height: 230px;
  min-width: 200px;
  background: #ffffff;
  margin: 10px 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-shrink: inherit;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
}

.card-analytics-dashboard:hover {
  background: #ebe3e3;
}

.card-dashboard:hover {
  background: #ebe3e3;

}

.chart {
  width: 104px;
  height: 66px;
  position: absolute;
  vertical-align: middle;
  margin-top: 66px;
  margin-left: -152px;
  border: 0px;
  background: none;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  color: rgb(135, 206, 235);
  padding: 0px;
  appearance: none;
  font-size: 40px;
  font-style: bold;
}

/* header */
.header-icon {
  font-size: 18px;
  padding: 15px 0 15px 30px;
  color: #252525;
  position: relative;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  vertical-align: middle;
  float: left;
  z-index: 999;
}

.header-icon i,
.header-icon img,
.header-icon span {
  cursor: pointer;
}

.header-icon.active .drop-down {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
}

/* i.fa.fa-eye,
.fa-eye-slash {
 position: absolute;
 margin-left: -25px;
 margin-top: 10px;
 cursor: pointer;
} */

.cart-add {
  width: 160px;
  position: relative;
  left: 90px;
  background-color: #ef233c;
}

/* .row {
 display: flex;
 margin-left: 0px;
 margin-right: 0px;
} */
.cart-add-btn {
  text-align: center;
  width: 100px;
  position: relative;
  left: 15px;
  background-color: #ef233c;
}

.cart-add-btn button {
  background-color: #ef233c;
  border: none;
  color: white;
  width: 30px;
}

.loader {
  position: fixed;
  z-index: 999999;
  background: rgba(255, 255, 255, 0.58);
  width: 100%;
  height: 100%;
}

.loaderAlign {
  position: absolute;
  top: 30%;
  left: 50%;
  text-align: center;
  margin-left: -35px;
  margin-top: -35px;
  z-index: 9999;
}

.inner-container {
  margin: 10px;
}

.dashboard-store-info-container {
  display: flex;
  margin-bottom: 20px;
}

.dashboard-store-info-inner {
  margin-bottom: 5px;
  margin-left: 30px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center
}

.dashboard-store-info-inner-verticalLine {
  height: 100%;
  width: 1px;
  background-color: gray
}

.dashboard-store-info-inner2 {
  margin-bottom: 5px;
  margin-right: 30px;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center
}

.dashboard-analytics-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap
}

.dashboard-OrderSummary-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap
}

.order-container {
  position: relative;
  display: flex;
  flex-direction: column
}

.order-inner-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 10px
}

.order-table-card {
  margin-right: 10px
}

.error-msg {
  color: #ef233c;
}

.header-AppHeader {
  padding: 10px;
  display: flex;
  flex-direction: row;
  position: fixed;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  z-index: 99
}

.header-AppLink-container {
  display: flex;
  flex-direction: row
}

.header-AppLink {
  margin-left: 10px;
  margin-right: 60px
}

.header-User-Info-container {
  display: flex;
  flex-direction: row
}

.header-Select-box {
  align-items: center;
  margin-right: 20px;
  justify-Content: center;
  display: flex
}

.header-logout {
  cursor: pointer;
  align-items: center;
  justify-content: center, ;
  display: flex
}

.header-username {
  margin-right: 20px
}

.header-logout-icon {
  margin-right: 10px
}

.nav-buttons-isChild {
  margin-left: 20px;
  display: flex;
  flex-direction: row
}

.nav-buttons2-isnotChild {
  display: flex;
  flex-direction: row
}

.nav-collapse-buttons {
  display: flex;
  justify-content: center;
  padding: 15px;
  background-color: rgba(39, 40, 53, 0.9)
}

.nav-collapse-buttons-nothighlighted {
  display: flex;
  justify-content: center;
  padding: 15px
}

.nav-collapse-icon-nonhighlighted {
  margin-right: 10px
}

.nav-collapse-icon-hihlighted {
  color: white;
  margin-right: 10px
}

.nav-expand-icon1 {
  color: white;
  margin-right: 10px
}

.nav-expand-icon2 {
  margin-right: 10px
}

.nav-expandable {
  position: absolute;
  right: 10%
}

.login-container {

  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover
}

.login-card {
  width: 400px;
  padding: 16px;
  justify-content: center;
  align-items: center
}

.login-heading {
  text-align: center;
  font-weight: 700;
  margin: 10px 10px 10px 10px;
  font-Size: 20px
}

.login-field-container {
  margin: 5px 40px 5px 40px
}

.login-button-container {
  display: flex;
  margin: 10px;
  justify-content: center;
  align-items: center
}

.product-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.button {
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

input.form-control.dateInput {
  background-color: white;
}


/* 
 .switch {
   position: relative;
   display: inline-block;
   width: 75px;
   height: 40px;
   background-color: #2c2c2c;
   color: #000;
   -webkit-transition: all 0.6s;
   transition: all 0.6s;
 }
 
 .switch.off,
 .switch.on {
   border-radius: 25px;
   padding: 5px;
   cursor: pointer;
 }
 
 .switch.off:before,
 .switch.off:after {
   -webkit-transition: 0.6s;
   transition: all 0.6s;
 }
 
 .switch.on:before,
 .switch.on:after {
   -webkit-transition: 0.6s;
   transition: all 0.6s;
 }
 
 .switch.off:before,
 .switch.on:before {
   display: block;
   position: absolute;
   content: "";
   height: 39px;
   width: 39px;
   left: 4px;
   top: 5px;
   background-color: #f2c74e;
   -webkit-transition: 0.6s;
   transition: all 0.6s;
   -webkit-transition-delay: 0.01s;
   transition-delay: 0.01s;
   -moz-box-shadow: inset -8px -8px 6px -6px #c7960f;
   -webkit-box-shadow: inset -8px -8px 6px -6px #c7960f;
   border-radius: 50%;
   box-shadow: inset -8px -8px 6px -6px #c7960f;
 }
 
 .switch.off:after {
   display: block;
   position: absolute;
   content: ": (";
   -o-transform: rotate(-270deg);
   -webkit-transform: rotate(-270deg);
   -ms-transform: rotate(-270deg);
   transform: rotate(-270deg);
   left: 17px;
   top: 15px;
 }
 
 .switch.on:before {
   -webkit-transform: translateX(38px);
   -ms-transform: translateX(38px);
   transform: translateX(38px);
 }
 
 .switch.on:after {
   position: absolute;
   content: ": )";
   -o-transform: rotate(90deg);
   -webkit-transform: rotate(90deg);
   -ms-transform: rotate(90deg);
   transform: rotate(90deg);
   left: 56px;
   top: 15px;
 }
  */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 25px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider.round {
  border-radius: 34px;
}

input:checked+.slider {
  background-color: #1b3c6b;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 10px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

.slider.round:before {
  border-radius: 50%;
}

input:checked+.slider:before {
  transform: translateX(26px);
}


.bistro .card-analytics-dashboard {
  height: auto;
  background: hsl(0, 0%, 100%);
  margin: 10px 10px;
  justify-content: initial;
  align-items: baseline;
  display: flex;
  flex-shrink: inherit;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
}

.mb-0 {
  margin-bottom: 0px;
}

.bistro .bistro_completed {
  background-color: rgb(30, 31, 41);
  position: absolute;
  right: 35px;
  top: 20px;
}

.bistro {
  display: block;
}

.desc-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.product-tag {
  background-color: #efebdb;
  color: #867535;
  font-weight: 400;
  letter-spacing: .8px;
}

.title-case {
  text-transform: capitalize;
}

.check-box {
  display: inline-block;
  padding-right: 10px;
  white-space: nowrap;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #006E74 !important;

  border-color: #006E74 !important;
}

.pagination>li>a,
.pagination>li>span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #1e1f29;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.text-underline {
  text-decoration: underline;
  color: blue;
}

.menu-active {
  font-weight: bold;
}

.offcanvas-menu nav span {
  display: block;
  padding: 12px 0;
  border-bottom: 1px solid #707070;
  font-size: 1em;
  color: #000;
}

.offcanvas-menu nav span:last-child {
  border-bottom: 0;
}

.offcanvas-menu span.logout {
  color: #d50606;
}

.offcanvas-menu span:hover {
  opacity: .7;
}

.submenu li span {
  padding: 10px 0;
}

.offcanvas-menu {
  z-index: 1000;
}

.customisable {
  background-color: #efebdb;
  color: #867535;
  border-radius: 5px;
  font-size: small;
}

body {
  background-color: white;
  font-family: 'RobotoDraft', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: small;
  line-height: 1.4;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

h5 {
  margin: 0px;
  font-size: 1.4em;
  font-weight: 700;
}

p {
  font-size: 12px;
}

.grid-panel {
  width: 100%;
  margin-left: 40px;
  display: inline-grid;
  grid-template-columns: auto auto auto auto auto;

}

/* End Non-Essential  */

.property-card {
  height: 25em;
  width: 25em;
  background-color: #f7f7f7;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  -webkit-box-shadow: 15px 15px 27px rgb(97 97 97 / 75%), -15px -15px 27px #ffffff;
  box-shadow: 20px 15px 27px rgb(97 97 97 / 75%), -15px -15px 27px #ffffff;
}

.card-title {
  text-align: center;
  align-items: center;
  font-size: 100px;
}

.card-title-custom {
  text-align: center;
  align-items: center;
  font-size: 100px;
}

.card-footer {
  align-items: center;
  text-align: center;
  padding-bottom: 10px;
}

.customButton {
  font-size: 16px;
  width: 163px;
  height: 48px;
  cursor: pointer;
  transition: all .2s ease-in-out;
  letter-spacing: 1px;
  border: none;
  justify-content: space-between;
  border-radius: 23px;
}


/* -- quantity box -- */

.qr-image-wrapper section {
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-top: 100%;
  border-radius: 16px;
}

.qr-image-wrapper section div {
  top: 0px;
  left: 0px;
  z-index: 1;
  box-sizing: border-box;
  border: 50px solid rgba(0, 0, 0, 0.3);
  box-shadow: rgba(255, 0, 0, 0.5) 0px 0px 0px 5px inset;
  position: absolute;
  width: 100%;
  height: 100%;
}


.scroll {
  height: 90vh;
  overflow: auto;
}

#planogram .btn.bigger {
  border-color: #ff77bc;
}

.Notification-Scroll {
  height: 90vh;
  overflow: auto;
}

.css-1rhbuit-multiValue {
  background-color: #000 !important;
  color: white !important;
  border-radius: 20px !important;
}

.css-12jo7m5 {
  color: white !important;
}

.freeImg {
  height: 35px;
}

.form-control:disabled {
  background-color: white;
}

.w-47 {
  width: 47%;
}

.bb-table {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.bb-box {
  display: table-cell !important;
}

.bb-box .bb-item {
  font-size: 22px;
  margin-bottom: 10px;
}

.bb-box .bb-cus {
  font-size: 18px;
  margin-bottom: 10px;
}

.bb-box .bb-sku-cus {
  font-size: 18px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.bb-box h1 {
  font-weight: bolder;
  font-size: 24px;
}

.bb-box .check-box #checkbox {
  width: 25px;
  height: 25px;
  margin-bottom: 10px;
}

.bb-box .bb-sku {
  font-size: 20px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.bistro .bb-btn {
  padding: 0px 25px;
  height: 40px;
  position: relative;
  top: -20px;
  left: 20px;
  border: beige;
  color: white;
  background-color: #393939;
  border-radius: 5px;
  cursor: pointer;
}

.vh-100 {
  height: 100vh;
}

.vw-100 {
  width: 100vw;
}


.shadow-dreamy {
  box-shadow: 0 1px 2px rgb(0 0 0 / 0), 0 2px 4px rgb(0 0 0 / 0), 0 4px 8px rgb(0 0 0 / 0), 0 8px 16px rgb(0 0 0 / 0), 0 16px 32px rgb(0 0 0 / 5%), 0 32px 64px rgb(0 0 0 / 5%);
}


/* Navbar css Start */

.sticky-nav {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1000;
}

/* Navbar css End */
.login .card {
  width: 35%;
  padding: 50px 0px;
  text-align: center;
}

.login .card input {
  border: none;
  background-color: white;
  box-shadow: 0 1px 2px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 7%), 0 4px 8px rgb(0 0 0 / 7%), 0 8px 16px rgb(0 0 0 / 7%), 0 16px 32px rgb(0 0 0 / 7%), 0 32px 64px rgb(0 0 0 / 7%);
  padding: 15px 20px;
  font-size: 18px;
  margin-bottom: 30px;
  border-bottom: 3px solid black;
}

.order-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

}

.quantity__minus,
.quantity__plus {
  display: block;
  width: 28px;
  height: 23px;
  margin: 0;
  background: white;
  text-decoration: none;
  text-align: center;
  line-height: 23px;
  border: 1px solid black;
}

.quantity__minus:hover,
.quantity__plus:hover {
  background: black;
  color: #fff;
}

.quantity__input {
  width: 32px;
  height: 23px;
  margin: 0;
  padding: 0;
  text-align: center;
  border-top: 2px solid #dee0ee;
  border-bottom: 2px solid #dee0ee;
  border-left: 1px solid #dee0ee;
  border-right: 2px solid #dee0ee;
  background: #fff;
  color: black;
  border: 1px solid black;
}

.quantity__minus:link,
.quantity__plus:link {
  color: black;
}

.quantity__minus:visited,
.quantity__plus:visited {
  color: #fff;
}

.btn-decline-success {
  color: #fff;
  background-color: #121212;
  border-color: #121212;
}

.btn-decline-outline-danger {
  color: #121212;
  border-color: #121212;
}

.btn-decline-success:hover {
  color: #fff;
  background-color: #121212;
}

.btn-decline-outline-danger:hover {
  background-color: #121212;
  color: white;
}

.alert-notify {
  background-color: #EFF3F8;
  border-color: #EFF3F8;
  color: black;
}

.decline-text {
  text-align: left;
}


.quantity__minus,

.quantity__plus {

  display: block;

  width: 28px;

  height: 23px;

  margin: 0;

  background: white;

  text-decoration: none;

  text-align: center;

  line-height: 23px;

  border: 1px solid black;

}

.quantity__minus:hover,

.quantity__plus:hover {

  background: black;

  color: #fff;

}

.quantity__input {

  width: 32px;

  height: 23px;

  margin: 0;

  padding: 0;

  text-align: center;

  border-top: 2px solid #dee0ee;

  border-bottom: 2px solid #dee0ee;

  border-left: 1px solid #dee0ee;

  border-right: 2px solid #dee0ee;

  background: #fff;

  color: black;

  border: 1px solid black;

}

.quantity__minus:link,

.quantity__plus:link {

  color: black;

}

.quantity__minus:visited,

.quantity__plus:visited {

  color: #fff;

}

.strike {
  text-decoration: line-through;
  text-decoration-color: red;
}

.text-black {
  text-decoration: underline;
  color: 'black'
}

.sideNav-img {
  width: 19px;
}

.promotion-desc-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 110px;
}

.promotion-coupon-desc-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70px;
}

.order-detail-desc-wrap {
  flex: 0 0 auto;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.coffe-machine {
  margin-top: 40px;
  margin-bottom: 0px;
}

.planogram-heading {
  margin-left: 25px;
}

.planogram-bottom {
  margin-left: 48px;
}

.bistro .bistro_completed {
  padding: 0px 25px;
  height: 40px;
  position: relative;
  top: -20px;
  left: 20px;
  border: beige;
  color: white;
  background-color: #393939;
  border-radius: 5px;
}

@media (min-width: 576px) {
  .modal-dialog-centered {
    min-height: calc(100% - 1rem);
  }
}

.image {
  width: 250px;
  float: left;
  margin: 20px;
}


p {
  margin: 0;
}

.performance-facts {
  border: 1px solid black;
  margin: 20px;
  float: left;
  width: 280px;
  padding: 0.5rem;

  border-collapse: collapse;

}

.performance-facts__title {
  font-weight: bold;
  font-size: 2rem;
  margin: 0 0 0.25rem 0;
}

.performance-facts__header {
  border-bottom: 10px solid black;
  padding: 0 0 0.25rem 0;
  margin: 0 0 0.5rem 0;

  margin: 0;

}

.performance-facts__table {
  width: 100%;

  border: 0;

}

th,
td {
  font-weight: normal;
  text-align: left;
  padding: 0.25rem 0;
  border-top: 1px solid black;
  white-space: nowrap;
}

td {
  text-align: right;

}

.blank-cell {
  width: 1rem;
  border-top: 0;
}

.thick-row {

  border-top-width: 5px;

}

.small-info {
  font-size: 0.7rem;
}

.performance-facts__table--small {
  @extend .performance-facts__table;
  margin: 0 0 0.5rem 0;
  border-bottom: 1px solid black;
}

td {

  text-align: left;

}

th,
td {
  border: 0;
  padding: 0;
}

/* hr.new4 {
 border: 1px solid rgb(20, 19, 19);
} */
.lineNutri {
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: rgb(19, 13, 13);
  width: 100%;
  height: 3px;
}

.biglineNutri {
  margin-top: 12px;
  margin-bottom: 12px;
  background-color: rgb(19, 13, 13);
  width: 100%;
  height: 5px;
}

.smalllineNutri {
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: rgb(19, 13, 13);
  width: 100%;
  height: 1px;

}

.col-sm-2.col-6 .quantity {
  max-width: 100%;
}

#order-options-bar .col-6 .form-select {
  width: 100% !important;
}

@media only screen and (max-width: 600px) {
  #all-offers .table-responsive .table {
    width: 1200px;
  }
}

.table>tbody {
  vertical-align: middle;
}

.mle-3 {
  margin-left: 8px !important;
}

iframe {
  display: none;
}

.sales-report td {
  text-align: center;
}

#footer {
  position: absolute;
  width: 100%;
}

.search-box.d-flex div {
  margin-left: -20px;
  margin-top: 6px;
}

.footer-bottom {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.report-desc-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 110px;
}

.react-datepicker__day--keyboard-selected {
  background-color: white;
  color: black;
}

.react-datepicker__day--keyboard-selected :hover {
  background-color: white;
  color: black;
}

.top-dotted-divider {
  border-top: 1px dashed #999;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #006E74;
  border-color: #006E74;
}

/*multi store*/
.modal-close {
  cursor: pointer;
  width: 38px;
  position: absolute;
  transition: all .3s;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -o-transition: all .3s;
  top: -20px;
  right: -8px;
}

.modal-close:hover {
  filter: brightness(80%);
}

.alert-prod-danger {
  border-color: red;
  border-radius: 1px;
  background-color: white;
}